<template>
  <tj-dialog
    v-model="visible"
    title="设置职位"
    width="900px"
    @close="onClose"
  >
    <el-container>
      <el-main>
        <el-table :data="tableData">
          <el-table-column label="序号" type="index" align="right"></el-table-column>
          <el-table-column label="职位名称" prop="name"></el-table-column>
          <el-table-column label="职位类型" align="center" width="89">
            <template #default="{ row }">
              <el-tag v-if="row.position_type === 1">全职</el-tag>
              <el-tag v-if="row.position_type === 2">兼职</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="保底金额" align="center" width="120">
            <template #default="{ row }">
              <template v-if="row.is_lowest">{{ row.lowest_amount }}</template>
              <template v-else>无</template>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="120">
            <template #default="{ row }">
              <el-button
                type="primary"
                :icon="Check"
                :disabled="row.id === currentPosition"
                @click="submit(row.id)"
              >选择</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer height="auto">
        <tj-pagination
          v-model="page"
          @change="getList"
        ></tj-pagination>
      </el-footer>
    </el-container>
    <template #footer>
      <el-button @click="close">关闭</el-button>
    </template>
  </tj-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { Check } from '@element-plus/icons-vue';
import { useDialog, usePagination } from '@tj/ui';
import request from '@/hooks/request';

const { page } = usePagination({ limit: 10 });
const tableData = ref([]);
function getList() {
  request.get('/admin/engineer/position', {
    params: {
      page: page.page,
      per_page: page.limit,
    },
  }).then((data) => {
    tableData.value = data.list;
    page.total = data.total;
  });
}

const emit = defineEmits(['submit']);
const currentPosition = ref();

const {
  visible,
  open,
  close,
  onOpen,
} = useDialog();
onOpen((data) => {
  currentPosition.value = data;
  getList();
});
function onClose() {
  currentPosition.value = undefined;
}

function submit(id) {
  close();
  emit('submit', id);
}

defineExpose({
  open,
});
</script>
